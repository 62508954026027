import Vue from 'vue';
import App from './App.vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import '@/global/register-element';

import '@/styles/index.scss'; // global css

import store from './store';
import router from './router';

import '@/icons'; // icon
import '@/permission'; // permission control
// vee-validate表单验证
import '@/utils/validate';
import resetMessage from '@/utils/resetMessage';
// iconfont
import './assets/iconfont/iconfont.css';

import '@/directive'

// set ElementUI lang to EN locale,, ElementLocale
// Vue.use(ElementUI, { zhLocale });
Vue.config.productionTip = false;
// Message消息提示每次只弹出一个
Vue.prototype.$message = resetMessage;

import ThemePicker from '@/components/ThemePicker';
Vue.component('ThemePicker', ThemePicker);

// 全局directive的写法
// 提交以后禁用按钮一段时间，防止重复提交
// import Vue from 'vue';
Vue.directive('noMoreClick', {
  inserted(el, binding) {
    el.addEventListener('click', (e) => {
      el.classList.add('is-disabled');
      el.disabled = true;
      setTimeout(() => {
        el.disabled = false;
        el.classList.remove('is-disabled');
      }, 2000);
    });
  },
});
// el单选切换报错bug
Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});
import { Message } from 'element-ui';
Vue.prototype.$message = Message;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');

// app.$mount('#app')
