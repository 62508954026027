// 导出属平台审核的路由规则  作废
import Layout from '@/layout/default-layout.vue';
// 每个子模块 其实 都是外层是layout  组件位于layout的二级路由里面
export default {
  name: 'examine',
  path: '/examine',
  component: Layout,
  redirect: '/examine/residencyApplication',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-opportunity',
    title: '平台审核',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'residencyApplication',
      path: 'residencyApplication',
      component: () => import('@/views/examine/EntryApplicationReview'),
      meta: {
        icon: 'el-icon-sold-out',
        title: '入驻申请审核',
      },
    },
    {
      name: 'storeInformation',
      path: 'storeInformation',
      component: () => import('@/views/examine/StoreInformationReview'),
      meta: {
        icon: 'el-icon-sell',
        title: '店铺资料审核',
      },
    },
    // {
    //   name: 'withdraw',
    //   path: 'withdraw',
    //   component: () => import('@/views/examine/withdraw'),
    //   meta: {
    //     icon: 'el-icon-sell',
    //     title: '提现审核列表',
    //   },
    // },
  ],
};
