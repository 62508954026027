import request from '@/utils/request';

export function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data,
  });
}

export function logout() {
  return request({
    url: '/admin/logout',
    method: 'post',
  });
}

export function getUserInfo(params) {
  return request({
    url: '/admin/selectMenuListByUser',
    method: 'get',
    params
  });
}

export function getLookProductList(params) {
  return request({
    url: '/lookProduct/getLookProductList',
    method: 'get',
    params,
  });
}

export function changePassword(data) {
  return request({
    url: '/admin/user/changePassword',
    method: 'post',
    data,
  });
}

// 首页

// 首页基本信息统计
export function getHomeBasicInfo() {
  return request({
    url: '/home/mainDate',
    method: 'get',
  });
}

// 首页排行榜
export function getRankSum(params) {
  return request({
    url: '/home/getBrandSumList',
    method: 'get',
    params,
  });
}

// 用户消费金额
export function getUserSum(params) {
  return request({
    url: '/home/ConsumptionDate',
    method: 'get',
    params,
  });
}

// 搜索数据排行
export function getSearchSum(params) {
  return request({
    url: '/home/searchDate',
    method: 'get',
    params,
  });
}

// 饼图接口
export function getOrderRing(params) {
  return request({
    url: '/home/chart/order/ring',
    method: 'get',
    params,
  });
}

// 销售统计
export function getSellLine(params) {
  return request({
    url: '/home/chart/order/price',
    method: 'get',
    params,
  });
}

// 订单统计
export function getOrderLine(params) {
  return request({
    url: '/home/chart/order/count',
    method: 'get',
    params,
  });
}

// 地图坐标
export function getMapInfo() {
  return request({
    url: '/home/dt/zb',
    method: 'get',
  });
}

// 地图统计
export function getOrderMap(params) {
  return request({
    url: '/home/chart/order/chartOrderInDt',
    method: 'get',
    params,
  });
}

// 审核

// 审核操作列表
export function getAuditList(params) {
  return request({
    url: '/admin/operate/audit/list',
    method: 'get',
    params,
  });
}

// 审核操作类型下拉框列表
export function getAuditTypeList(params) {
  return request({
    url: '/admin/operate/audit/category/list',
    method: 'get',
    params,
  });
}

// 审核处理 - 同意，拒绝
export function auditOperate(data) {
  return request({
    url: '/admin/operate/audit/handle',
    method: 'post',
    data,
  });
}

// 审核 - 审核开关是否开启
export function getAuditSet(params) {
  return request({
    url: '/admin/operate/audit/config',
    method: 'get',
    params,
  });
}

// 审核 - 修改审核配置信息
export function changeAuditSet(data) {
  return request({
    url: '/admin/operate/audit/switch/change',
    method: 'post',
    data,
  });
}
