<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <div class="dropdown">
    <button
      @mouseenter="showDropdown"
      @mouseleave="hideDropdown"
      class="trigger"
    >
      客服工作台
    </button>
    <div
      class="selectBox"
      v-show="isDropdownVisible"
      @mouseenter="showDropdown"
      @mouseleave="hideDropdown"
    >
      <div class="selectTop">
        <div class="selectTop1">
          <p
            >总任务数:<span> {{ total }}</span></p
          >
          <p
            >最后刷新时间(3分钟一次): <span>{{ currentTime }}</span></p
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 80px; margin-bottom: 10px"
            @click="suaxin"
            >刷新</el-button
          >
        </div>
      </div>
      <div class="select-bt">
        <div class="select-left">
          <p class="pp">售前订单</p>
          <ul model="this.list">
            <li
              >需要报单数：<span @click="navigatePageToOrder(-1)">
                {{ this.list.customsNumber }}</span
              ></li
            >
            <li
              >24到48小时待发货订单:<span @click="navigatePageToOrder(1)">
                {{ this.list.shipmentsNumber24And48 }}</span
              ></li
            >
            <li
              >超48小时待发货订单:<span @click="navigatePageToOrder(2)">
                {{ this.list.shipmentsNumber }}</span
              >
            </li>
            <li
              >待处理的订单：<span @click="navigatePageToOrder(3)">
                {{ this.list.disposeNumber }}</span
              ></li
            >
            <li
              >有售后已发货订单:<span @click="navigatePageToOrder(4)">
                {{ this.list.disposeNumberRefund }}</span
              ></li
            >
          </ul>
        </div>
        <div class="select-left">
          <p class="pp">售后订单</p>
          <ul model="this.list">
            <li
              >仅退款 (待发货未报单) 订单：<span
                @click="navigatePageToorderRefund(5)"
              >
                {{ this.list.agreeRefundNumber }}</span
              >
            </li>
            <li
              >仅退款 (待发货已报单) 订单:<span
                @click="navigatePageToorderRefund(6)"
              >
                {{ this.list.refundReturnNumber }}</span
              >
            </li>
            <li
              >仅退款 (已发货) 订单:<span
                @click="navigatePageToorderRefund(7)"
                >{{ this.list.refundReturnYfhNumber }}</span
              >
            </li>
            <li
              >退货退款 (待处理) 订单:<span
                @click="navigatePageToorderRefund(8)"
              >
                {{ this.list.refundReturnDclNumber }}</span
              >
            </li>
            <li
              >退货退款 (处理中) 订单:<span
                @click="navigatePageToorderRefund(9)"
              >
                {{ this.list.refundReceiptNumber }}</span
              >
            </li>
          </ul>
        </div>
        <div class="select-right">
          <p class="pp">通知信息</p>
          <ul model="this.list">
            <!-- <li>三天内新增加产品数：<span @click="navigatePageToProduct(1)">{{ this.list.newProductNumber }}</span></li>
                        <li>三天内下架的产品数:<span @click="navigatePageToProduct(0)">{{ this.list.outProductNumber }}</span></li> -->
            <!-- <router-link :to="{ name: 'allGoods', params: { id: 123 } }">待确认收货并退款数:{{ this.list.refundReceiptNumber }}</router-link> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import axios from 'axios';
  import { getWorkList } from '@/api/workbench';
  import { bus } from '@/utils/eventBus'; // 导入EventBus

  export default {
    data() {
      return {
        list: {},
        currentTime: '',
        isDropdownVisible: false,
        // 记录上一次点击刷新按钮时间
        lastClickTime: null,
      };
    },
    created() {
      // 初始加载时获取时间并设置定时器每隔60秒更新时间
      this.showCurrentTime(); // 首次加载时立即获取时间
      this.timer = setInterval(this.showCurrentTime, 180000); // 每隔60秒执行一次updateTime
    },
    computed: {
      total() {
        // 计算总数并返回
        return this.calculateTotal(this.list);
      },
    },
    watch: {
      // $route() {
      //     location.reload();
      // },
    },

    methods: {
      showDropdown() {
        this.isDropdownVisible = true;
      },
      hideDropdown() {
        this.isDropdownVisible = false; //
      },
      async getList() {
        const res = await getWorkList();
        this.list = res;
        // console.log('list', this.list);
      },
      suaxin() {
        const currenttime = Date.now();
        if (currenttime - this.lastClickTime >= 10000) {
          this.showCurrentTime();
          this.lastClickTime = currenttime;
        } else {
          const a = Math.floor(
            (10000 - (currenttime - this.lastClickTime)) / 1000
          );
          this.$message({
            message: `${a}秒后可刷新`,
            type: 'warning',
          });
        }
      },

      showCurrentTime() {
        const now = new Date();
        this.currentTime = now.toLocaleTimeString(); // 获取当前时间并存储在数据属性中
        this.getList();
      },

      // 售前
      navigatePageToOrder(markIndex) {
        this.$router.replace(`/order/orderList?markIndex=${markIndex}`);
        // 清空输入框在orderSearch组件订阅
        bus.$emit('clearInput');
        // 重新请求数据 在OrderTable组件订阅
        bus.$emit('redata');
        console.log(markIndex, 'markindex');
      },
      // 售后
      navigatePageToorderRefund(markIndex) {
        this.$router.replace(`/order/saleOrder?markIndex=${markIndex}`);
        bus.$emit('clearInput');
        bus.$emit('redata');
        console.log(markIndex, 'markindex');
      },
      // （线上）需要报单数 (线上)48小时内待发货 待处理数的订单：
      // navigatePageToOrder(markIndex) {
      //     this.$router.replace(`/order/orderList?markIndex=${markIndex}`);
      //     bus.$emit('clearInput');
      //     console.log(markIndex, 'markindex')
      // },

      // 三天内新增加产品数 三天内下架的产品数
      navigatePageToProduct(markIndex) {
        this.$router.push(`/commodity/allGoods?markIndex=${markIndex}`);
        bus.$emit('clearInput');
      },
      // // 待同意退货退款 仅退款   待确认收货并退款数： (线下)48小时内待发货:
      // navigatePageToRefund(markIndex) {
      //     this.$router.push(`/order/saleOrder?markIndex=${markIndex}`);
      //     bus.$emit('clearInput');

      // },

      calculateTotal(obj) {
        // 编写计算总数的逻辑，根据对象的属性值进行计算
        let sum = 0;
        for (const key in obj) {
          if (typeof obj[key] === 'number') {
            if (key !== 'newProductNumber' && key !== 'outProductNumber') {
              sum += obj[key];
            }
          }
        }
        return sum;
      },
    },

    beforeDestroy() {
      // 在组件销毁前清除定时器，防止内存泄漏
      clearInterval(this.timer);
    },
  };

  {
    /* <div class="select-left">
                    <ul model="this.list">
                        <li>（线上）需要报单数：<span @click="navigatePageToOrder(-1)"> {{ this.list.customsNumber }}</span></li>
                        <li>(线上)48小时内待发货:<span @click="navigatePageToOrder(1)"> {{ this.list.shipmentsNumber }}</span></li>
                        <li>(线下)48小时内待发货:<span @click="navigatePageToOrder(6)">{{ this.list.offlineShipmentsNumber }}</span>
                        </li>
                        <li>三天内新增加产品数：<span @click="navigatePageToProduct(1)">{{ this.list.newProductNumber }}</span></li>
                        <li>三天内下架的产品数:<span @click="navigatePageToProduct(0)">{{ this.list.outProductNumber }}</span></li>
                    </ul>
                </div>
                <div class="select-right">
                    <ul model="this.list">
                        <li>
                            待处理数的订单：<span @click="navigatePageToOrder(2)">{{ this.list.disposeNumber }}</span>
                        </li>
                        <li>待同意仅退款：<span @click="navigatePageToRefund(5)">{{ this.list.agreeRefundNumber }}</span></li>
                        <li>待同意退货退款：<span @click="navigatePageToRefund(7)">{{ this.list.agreeRefundNumber }}</span></li>
                        <li>待确认收货并退款数：<span @click="navigatePageToRefund(4)">{{ this.list.refundReceiptNumber }}</span></li>
                        <!-- <router-link :to="{ name: 'allGoods', params: { id: 123 } }">待确认收货并退款数:{{ this.list.refundReceiptNumber }}</router-link> -->
                    </ul>
                </div> */
  }
</script>

<style scoped>
  .dropdown {
    position: relative;
  }

  .trigger {
    cursor: pointer;
    height: 30px;
    border-radius: 10%;
    background-color: aqua;
  }

  .selectBox {
    width: 850px;
    height: 300px;
    border: 1px solid black;
    position: absolute;
    top: 30px;
    left: -650px;
    background-color: #f0f0f0;
    z-index: 800;
    border-radius: 0 0 3% 3%;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  }

  ul {
    list-style-type: none;
    padding-left: 10px;
    margin: 2px 0;
  }

  li {
    padding: 8px 3px;
    cursor: pointer;
  }
  li span {
    padding-right: 10px;
  }
  .pp {
    padding-left: 10px;
    color: red;
    font-weight: bold;
    margin: 10px 0 10px 0;
  }

  #select {
    width: 100px;
    height: 30px;
  }

  .selectTop {
    padding: 10px 0 0 10px;
    background-color: bisque;
  }

  .selectTop1 {
    display: flex;
    justify-content: space-around;
  }

  .select-bt {
    display: flex;
    width: 100%;
    /* height: 100%; */
    padding-left: 20px;
    overflow: hidden;
    background: #f3f3f8;
  }

  .select-left {
    background-color: rgb(243, 243, 248);
    width: 35%;
    height: calc(400-93.333) px;
    text-align: left;
  }

  .select-right {
    background-color: rgb(243, 243, 248);
    width: 30%;
    padding-left: 20px;
    height: calc(400-93.333) px;
    text-align: left;
  }

  span {
    color: blue;
    font-weight: 800;
  }
</style>
