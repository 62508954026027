module.exports = {
  title: '华优源信息管理平台',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   * 固定头部
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   * 显示左侧菜单logo
   */
  sidebarLogo: true,
};
