import request from '@/utils/request';
// 数据
export function getWorkList(data) {
    return request({
      url: '/home/operatinalData',
      method: 'get',
      data,
    });
  }
  /*
 // 待报单  线上48小时没有发货   待处理的订单 
  export function getOnlineFhorder(markIndex) {
    return request({
      url: `/order/orderList?markIndex=${markIndex}&page=1&size=10&constant=1&orderState=1`,
      method: 'get',
      markIndex,
    });
  }
// 线下48小时内没发货
  export function getOfFhorder(orderState) {
    return request({
      url: `/order/orderList?orderState=${orderState}&page=1&size=10&constant=1`,
      method: 'get',
      orderState,
    });
  }
  // 三天内上下架的商品  
  export function getUporder(state) {
    return request({
      url: `/product/productList?state=${state}&page=1&size=10&constant=1`,
      method: 'get',
      state,
    });
  }
  
  // 系统中所有退款退货的订单 系统中所有仅退款的订单  
   export function getTkorder(refundType) {
    return request({
      url: `/orderRefund/orderList?refundType=${refundType}&page=1&size=10&constant=1&refundState=1`,
      method: 'get',
      refundType,
    });
  }

  // 系统中待确认收货并退款数   
  export function getStorder(refundState) {
    return request({
      url: `orderRefund/orderList?refundState=${refundState}&page=1&size=10&constant=1`,
      method: 'get',
      refundState,
    });
  }

  */
  