// 导出属审核的路由规则
import Layout from '@/layout/default-layout.vue';
// 每个子模块 其实 都是外层是layout  组件位于layout的二级路由里面
export default {
  name: 'audit',
  path: '/audit',
  component: Layout,
  redirect: '/audit/auditGoods',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-opportunity',
    title: '审核',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'auditGoods',
      path: 'auditGoods',
      component: () => import('@/views/audit/auditGoods'),
      meta: {
        icon: 'el-icon-shopping-bag-1',
        title: '商品操作',
      },
    },
    {
      name: 'auditMember',
      path: 'auditMember',
      component: () => import('@/views/audit/auditMember'),

      meta: {
        icon: 'el-icon-user',
        title: '会员操作',
      },
    },
    {
      name: 'auditOperating',
      path: 'auditOperating',
      component: () => import('@/views/audit/auditOperating'),
      meta: {
        icon: 'el-icon-ship',
        title: '运营操作',
      },
    },
    {
      name: 'auditService',
      path: 'auditService',
      component: () => import('@/views/audit/auditService'),
      meta: {
        icon: 'el-icon-shopping-bag-1',
        title: '客服审核',
      },
    },
    // {
    //   name: 'gylProduceAudit',
    //   path: 'gylProduceAudit',
    //   component: () => import('@/views/audit/auditgylGoods'),
    //   meta: {
    //     icon: 'el-icon-shopping-bag-1',
    //     title: '供应链商品审核',
    //   },
    // },
  ],
};
