// 导出属于订货单系统的路由规则
import Layout from '@/layout/default-layout.vue';
export default {
  name: 'purchase ',
  path: '/purchase',
  component: Layout,
  redirect: '/purchase/supplierList',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-order',
    title: '订货单系统',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'supplierList',
      path: 'supplierList',
      component: () => import('@/views/purchaseOrder/supplier'),
      meta: {
        icon: 'el-icon-sold-out',
        title: '厂家列表',
      },
    },
    {
      name: 'SupplierProductsList',
      path: 'SupplierProductsList',
      component: () => import('@/views/purchaseOrder/SupplierProducts'),
      meta: {
        icon: 'el-icon-sell',
        title: '厂家产品列表',
      },
    },
    {
        name: 'orderGoods',
        path: 'orderGoods',
        component: () => import('@/views/purchaseOrder/orderGoods'),
        meta: {
          icon: 'el-icon-sell',
          title: '订货列表',
        },
      },
      {
        name: 'shipment',
        path: 'shipment',
        component: () => import('@/views/purchaseOrder/shipment'),
        meta: {
          icon: 'el-icon-sell',
          title: '出货列表',
        },
      },
  ],
};
