// 导出属资金中心的路由规则
import Layout from '@/layout/default-layout.vue';
// 每个子模块 其实 都是外层是layout  组件位于layout的二级路由里面
export default {
  name: 'fundingCenter',
  path: '/fundingCenter',
  component: Layout,
  redirect: '/fundingCenter/funding',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-money',
    title: '资金中心',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'funding',
      path: 'funding',
      component: () => import('@/views/FundingCenter/FundingCenter'),
      meta: {
        icon: 'el-icon-money',
        title: '资金中心',
      },
    },
    {
      name: 'szdetail',
      path: 'szdetail',
      component: () => import('@/views/platStore/grade'),
      meta: {
        icon: 'el-icon-document',
        title: '收支明细',
      },
    },
  ],
};
