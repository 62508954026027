<template>
  <div class="navbar">
    <div style="display: flex; align-items: center">
      <el-image src="https://sy.hyylm.com/d4093c5c18bd4b2f903d8cbe9e3467d4.png"
        style="margin-right: 8px; width: 33px; height: 33px">
      </el-image>
      <h5 style="margin: 0px; font-size: 18px; line-height: 1.4">
        华优源商城管理系统
      </h5>
    </div>
    <div class="navbar-right">
      <!-- <el-badge :value="200" :max="99" type="primary" class="message">
        <el-button type="text" size="small">消息</el-button>
      </el-badge> -->
      <!-- 主题色 -->
      <!-- <ThemePicker class="right-menu" /> -->
      <div><Workbench></Workbench></div>
      <div class="right-menu">
        <el-dropdown class="avatar-container" trigger="hover">
          <div class="avatar-wrapper">
            <!-- <img src="../.././assets/images/icon/logo.png" class="user-avatar"> -->
            <img src="../.././assets/images/login/oval@2x.png" class="user-avatar" />
            <div>
              <div class="name">欢迎您！</div>
              <div class="name">{{ username || '超级管理员' }}</div>
            </div>
            <i class="el-icon-caret-bottom" style="margin-right: 10px; color: #f00" />
          </div>
          <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <router-link to="/">
              <el-dropdown-item>首页</el-dropdown-item>
            </router-link>
            <!-- <a target="_blank" href="https://gitee.com/raglan/hyy01.git">
              <el-dropdown-item>项目地址</el-dropdown-item>
            </a>
            <a target="_blank" href="https://panjiachen.github.io/vue-element-admin-site/#/">
              <el-dropdown-item>Docs</el-dropdown-item>
            </a> -->
            <el-dropdown-item divided @click.native="passwordDialog = true">
              <span style="display: block">修改密码</span>
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="logout">
              <span style="display: block">退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog title="修改登录密码" :visible.sync="passwordDialog" width="30%" center append-to-body>
      <el-form ref="form" :model="form" :rules="rules" size="small">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input v-model="form.oldPassword" placeholder="请输入旧密码" />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="form.newPassword" placeholder="请输入新密码" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm">取 消</el-button>
        <el-button type="primary" @click="changePassword">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Workbench from '../workbench/workbench.vue';
import { validUserPassword } from '@/utils/validate';
import { RonRivest } from '@/utils/index';
import { mapGetters } from 'vuex';
import { logout, changePassword } from '@/api/user';
export default {

  components: {
    Workbench,
  },
  data() {
    const validPassword = (rule, value, callback) => {
      if (!validUserPassword(value)) {
        callback(new Error('密码格式错误'));
      } else {
        callback();
      }
    };
    showDropDown: false;
    return {
      passwordDialog: false,
      form: {
        newPassword: null,
        oldPassword: null,
      },
      rules: {
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 18, message: '密码在6-18位之间', trigger: 'blur' },
          { trigger: 'blur', validator: validPassword },
        ],
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          { min: 6, max: 18, message: '密码在6-18位之间', trigger: 'blur' },
          { trigger: 'blur', validator: validPassword },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['sidebar', 'username']),
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      await logout();
      await this.$store.dispatch('user/logout');
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    // 修改登录密码
    async changePassword() {
      await this.$refs.form.validate();
      const userId = this.$store.getters.userId;
      const oldPassword = RonRivest(this.form.oldPassword);
      const newPassword = RonRivest(this.form.newPassword);
      console.log({ userId, oldPassword, newPassword });
      let res = await changePassword({ userId, oldPassword, newPassword });
        if(!res){
             this.$message.success('修改密码成功，请重新登录');
        }
      this.resetForm();
    },
    resetForm() {
      this.passwordDialog = false;
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  padding: 0 20px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid rgb(229, 230, 235);

  .navbar-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-content {
    display: none;
  }

  .navbar-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .message {
      margin-right: 30px;
      margin-top: 5px;
    }

    .right-menu {
      display: flex;
      justify-content: center;
      align-items: center;

      .avatar-container {
        margin: 0 30px;

        .avatar-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          .user-avatar {
            cursor: pointer;
            width: 26px;
            height: 26px;
            border-radius: 5px;
            margin-right: 10px;
            vertical-align: middle;
          }

          .name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;
            color: #e60012;
          }

          .el-icon-caret-bottom {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: 6px;
            font-size: 12px;
          }
        }

        .el-icon-caret-bottom:before {
          color: #909399;
        }
      }
    }
  }
}
</style>
