import {
  setToken,
  getToken,
  removeToken,
  getTime,
  setTime,
  removeTime,
} from '@/utils/auth';
import { nowTime } from '@/filters/index';
import { getUserInfo, getAuditSet } from '@/api/user';

// --------------

const state = {
  token: getToken() || undefined,
  loginTime: getTime() || '',
  userInfo: JSON.parse(localStorage.getItem('user')) || {},
  username: localStorage.getItem('user_name') || null,
  userId: localStorage.getItem('user_id') || null,
  openAudit: localStorage.getItem('audit') || null,
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    setToken(token);
  },
  removeToken(state) {
    state.token = '';
    removeToken();
  },
  setLoginTime(state, time) {
    state.loginTime = time;
    setTime(time);
  },
  removeLoginTime(state) {
    state.loginTime = '';
    removeTime();
  },
  setUserInfo(state, data) {
    state.userInfo = data;
    state.username = data.name;
    state.userId = data.userId;
    localStorage.setItem('user_name', data.name);
    localStorage.setItem('user_id', data.userId);
    localStorage.setItem('user', JSON.stringify(data));
  },
  removeUserInfo(state) {
    state.userInfo = {};
    state.username = null;
    state.userId = null;
    localStorage.removeItem('user');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_id');
  },
  setAudit(state, openAudit) {
    state.openAudit = openAudit;
    localStorage.setItem('openAudit', openAudit);
  },
  removeAudit(state) {
    state.openAudit = '';
    localStorage.removeItem('openAudit');
  },
};

const actions = {
  // 登录
  async login(store, formData) {
    store.commit('setToken', formData);
    // 设置登录时间
    await store.commit('setLoginTime', nowTime());
    // 加这个 affiliationType 菜单所属 0：平台所属，1：供应商所属
    const res = await getUserInfo({ affiliationType: 0 });
    store.commit('setUserInfo', res);
  },
  async getUserInfo(store) {
    const res = await getUserInfo({ affiliationType: 0 });
    // 将整个的个人信息设置到用户的vuex数据中
    store.commit('setUserInfo', res);
    return res;
  },
  // 审核权限
  async getAudit(store, openAudit) {
    if (openAudit === undefined) {
      const res = await getAuditSet();
      store.commit('setAudit', res.openAudit);
    } else {
      store.commit('setAudit', openAudit);
    }
    return store.state.openAudit;
  },

  // 退出
  async logout(store) {
    // 清除缓存
    store.commit('removeToken');
    store.commit('removeLoginTime');
    store.commit('removeUserInfo');
    // window.localStorage.clear()
    // localStorage.removeItem('key')
    // localStorage.clear();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
