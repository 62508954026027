import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import settings from './modules/settings';
import user from './modules/user';
import commodity from './modules/commodity';
import permission from './modules/permission';
import tagsView from './modules/tagsView';
import goods from './modules/goods';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    app,
    settings,
    permission,
    user,
    commodity,
    tagsView,
    goods,
  },
  getters,
});

export default store;
