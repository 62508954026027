// 导出属于设置的路由规则
import Layout from '@/layout/default-layout.vue';
export default {
  name: 'setting',
  path: '/setting',
  component: Layout,
  redirect: '/setting/versionSet',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-tools',
    title: '设置',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'versionSet',
      path: 'versionSet',
      component: () => import('@/views/setting/versionSet'),
      meta: {
        icon: 'el-icon-setting',
        title: '版本设置',
      },
    },
    {
      name: 'paySet',
      path: 'paySet',
      component: () => import('@/views/setting/paySet'),
      meta: {
        icon: 'el-icon-sell',
        title: '支付设置',
      },
    },
    {
      name: 'pushNotification',
      path: 'pushNotification',
      component: () => import('@/views/setting/pushNotification'),
      meta: {
        icon: 'el-icon-message',
        title: '消息推送',
      },
    },
    {
      name: 'noteSet',
      path: 'noteSet',
      component: () => import('@/views/setting/noteSet'),
      meta: {
        icon: 'el-icon-chat-round',
        title: '短信设置',
      },
    },
    {
      name: 'otherDynamics',
      path: 'otherDynamics',
      component: () => import('@/views/setting/otherDynamics'),
      meta: {
        icon: 'el-icon-phone-outline',
        title: '其他设置',
      },
    },
  ],
};
