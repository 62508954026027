// 导出属于平台端审核的路由规则
import Layout from '@/layout/default-layout.vue';
export default {
  name: 'examine',
  path: '/examine',
  component: Layout,
  redirect: '/examine/residencyApplication',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-opportunity',
    title: '平台审核',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'residencyApplication',
      path: 'residencyApplication',
      component: () => import('@/views/examine/EntryApplicationReview'),
      meta: {
        icon: 'el-icon-sold-out',
        title: '入驻申请审核',
      },
    },
    {
      name: 'storeInformation',
      path: 'storeInformation',
      component: () => import('@/views/examine/StoreInformationReview'),
      meta: {
        icon: 'el-icon-sell',
        title: '店铺资料审核',
      },
    },
    {
      name: 'gylProduceAudit',
      path: 'gylProduceAudit',
      component: () => import('@/views/audit/auditgylGoods'),
      meta: {
        icon: 'el-icon-shopping-bag-1',
        title: '供应链商品审核',
      },
    },
    {
      name: 'withdraw',
      path: 'withdraw',
      component: () => import('@/views/examine/withdraw'),
      meta: {
        icon: 'el-icon-sell',
        title: '提现审核列表',
      },
    },
    {
      name: 'OperationPlanReview',
      path: 'OperationPlanReview',
      component: () => import('@/views/examine/OperationPlanReview'),
      meta: {
        icon: 'el-icon-s-opportunity',
        title: '运营方案审核',
      },
    },
  ],
};
