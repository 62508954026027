import store from '@/store';
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/');
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    );
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ');
  if (!search) {
    return {};
  }
  const obj = {};
  const searchArr = search.split('&');
  searchArr.forEach((v) => {
    const index = v.indexOf('=');
    if (index !== -1) {
      const name = v.substring(0, index);
      const val = v.substring(index + 1, v.length);
      obj[name] = val;
    }
  });
  return obj;
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function listToTreeDate(list, topid) {
  // 算法一:递归从父部门找子部门的算法
  const arr = [];
  list.forEach((item) => {
    if (item.pid === topid) {
      item.children = listToTreeDate(list, item.id);
      // 将内容放到数组
      arr.push(item);
    }
  });
  return arr;
}

import JSEncrypt from 'jsencrypt';

const encryptor = new JSEncrypt(); // 创建加密对象实例

const publiukey =
  `-----BEGIN PUBLIC KEY-----` +
  `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC5RLrck9Wgt2B8moV+6CeC0rb09rilj9uMkVMRnihpic6yMqdvG5kLcqdxLaS3ANLbAiHMHYxuFE5j7XSA6l4vJYku1RJju+dsLf+sC+tFc+v3iSHsvUI+vcruQJ9oQTzyjY1sXqJ50JbJhm3R02vmbkZKkGHc60Dtr+hPwfgwhwIDAQAB` +
  `-----END PUBLIC KEY-----`;

encryptor.setPublicKey(publiukey); //设置公钥

export const RonRivest = (val) => {
  return encryptor.encrypt(val);
};

/**
 * @param {string} url
 * @returns {Object}
 */
export function listToDate(list) {
  // 算法一:递归从父部门找子部门的算法
  const arr = [];
  list.forEach((item) => {
    if (item.userId != 31 && item.userId != 39 && item.userId != 0) {
      // 将内容放到数组
      arr.push(item);
    }
  });
  return arr;
}

// 加密
export function MD5data(input, Boolean, type) {
  console.log(input.transferAmountLimit);
  var data = Object.assign({}, input);
  if (type == 1) {
    data.wxMchid = data.wxMchid.slice(0, 10);
    data.wxApiKey = data.wxApiKey.slice(0, 32);
    data.wxApiV3Key = data.wxApiV3Key.slice(0, 32);
    if (Boolean && input.transferAmountLimit == 999) {
      data.wxMchid += Math.floor(Math.random() * 1000 + 100);
      data.wxApiKey += Math.floor(Math.random() * 1000 + 100);
      data.wxApiV3Key += Math.floor(Math.random() * 1000 + 100);
    }
  } else if (type == 2) {
    data.aliPid = data.aliPid.slice(0, 16);
    data.aliPrivateKey = data.aliPrivateKey.slice(0, 1624);
    if (Boolean && input.transferAmountLimit == 999) {
      data.aliPid += Math.floor(Math.random() * 1000 + 100);
      data.aliPrivateKey += Math.floor(Math.random() * 1000 + 100);
    }
  }
  return data;
}
