import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// 引入全部本地路由
const modules = require.context('./modules', false, /\.js$/);

function formatModules(_modules, result) {
  _modules.keys().forEach((key) => {
    const defaultModule = _modules(key).default;
    if (!defaultModule) return;
    const moduleList = Array.isArray(defaultModule)
      ? [...defaultModule]
      : [defaultModule];
    result.push(...moduleList);
  });
  return result;
}

const appRoutes = formatModules(modules, []);

const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      redirect: 'login',
    },
    {
      path: '/login',
      component: () => import('@/views/login/index'),
      hidden: true,
    },
    {
      path: '/404',
      component: () => import('@/views/404'),
      hidden: true,
    },
    {
      path: '/redirect',
      component: () => import('@/layout/components/redirect.vue'),
    },
    ...appRoutes,
  ],
});

export default router;
