// 导出属于权限的路由规则
import Layout from '@/layout/default-layout.vue';
export default {
  name: 'permission',
  path: '/permission',
  component: Layout,
  redirect: '/permission/roleManagement',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-check',
    title: '权限',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'roleManagement',
      path: 'roleManagement',
      component: () => import('@/views/permission/roleManagement'),
      meta: {
        icon: 'el-icon-user',
        title: '角色管理',
      },
    },
    {
      name: 'administratorOperation',
      path: 'administratorOperation',
      component: () => import('@/views/permission/administratorOperation'),
      meta: {
        icon: 'el-icon-monitor',
        title: '管理员操作',
      },
    },
    {
      name: 'menuManager',
      path: 'menuManager',
      component: () => import('@/views/permission/menuManager'),
      meta: {
        icon: 'el-icon-notebook-1',
        title: '菜单管理',
      },
    },
    {
      name: 'operationLog',
      path: 'operationLog',
      component: () => import('@/views/permission/operationLog'),
      meta: {
        icon: 'el-icon-mouse',
        title: '操作日志',
      },
    },
  ],
};
