// 导出属于财务的路由规则
import Layout from '@/layout/default-layout.vue';
export default {
  name: 'finance',
  path: '/finance',
  component: Layout,
  redirect: '/finance/payRecord',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-finance',
    title: '财务',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'payRecord',
      path: 'payRecord',
      component: () => import('@/views/finance/payRecord'),
      meta: {
        icon: 'el-icon-collection',
        title: '充值记录',
      },
    },
    {
      name: 'withdraw',
      path: 'withdraw',
      component: () => import('@/views/finance/withdraw'),
      meta: {
        icon: 'el-icon-printer',
        title: '提现申请',
      },
    },
    {
      name: 'balance',
      path: 'balance',
      component: () => import('@/views/finance/balance'),
      meta: {
        icon: 'el-icon-toilet-paper',
        title: '余额明细',
      },
    },
    {
      name: 'integration',
      path: 'integration',
      component: () => import('@/views/finance/integration'),
      meta: {
        icon: 'el-icon-football',
        title: '积分明细',
      },
    },
    {
      name: 'downloadBill',
      path: 'downloadBill',
      component: () => import('@/views/finance/downloadBill'),
      meta: {
        icon: 'el-icon-download',
        title: '账单下载',
      },
    },
     {
      name: 'capitalCenter',
      path: 'capitalCenter',
      component: () => import('@/views/finance/capitalCenter'),
      meta: {
        icon: 'el-icon-download',
        title: '资金中心',
      },
    },
    {
      name: 'payDetail',
      path: 'payDetail',
      component: () => import('@/views/finance/payDetail'),
      meta: {
        icon: 'el-icon-download',
        title: '收支明细',
      },
    },
  ],
};
