<script>
  import { mapState } from 'vuex';
  import { Notification } from 'element-ui';

  import { bus } from '@/utils/eventBus';

  export default {
    data() {
      return { timer: null, list: [] };
    },
    mounted() {
      this.int();
      this.timingStart();

      bus.$on('productWarnTimingStart', this.timingStart);
    },
    computed: {
      ...mapState({
        warnList: (state) => state.goods.warnList,
      }),
    },
    methods: {
      timingStart() {
        if (this.timer) return;

        const timeout = 1000 * 60 * 5;

        this.timer = setInterval(() => {
          this.int();
        }, timeout);
      },

      async int() {
        const bool = await this.$store.dispatch('goods/getProductWarnList', {
          page: 1,
          size: 20,
        });

        if (!bool) {
          clearInterval(this.timer);
          this.timer = null;
        } else {
          this.renderNotification();
        }
      },

      renderNotification() {
        this.list.map((dom) => dom.close());
        this.list = [];

        for (let i = 0; i < this.warnList.length; i++) {
          (() => {
            setTimeout(() => {
              const dom = Notification({
                title: this.warnList[i].title,
                dangerouslyUseHTMLString: true,
                message: (
                  <div style="display: flex;align-items: center;">
                    <span style="margin-right: 5px;">
                      规格: {this.warnList[i].spec || '无'}
                    </span>
                    <span style="margin-right: 5px;">
                      剩余库存:{this.warnList[i].inventory || ''}
                    </span>

                    <el-link
                      type="primary"
                      onClick={() => this.skip(this.warnList[i].productId)}
                    >
                      编辑
                    </el-link>
                  </div>
                ),

                type: 'warning',
                duration: 0,
              });
              this.list.push(dom);
            }, 500);
          })();
        }
      },
      skip(productId) {
        const { path, fullPath } = this.$route;

        if (path === '/commodity/allGoods') {
          this.$router.replace({
            path: `/redirect?path=${path}?productId=${productId}`,
          });
        } else {
          this.$router.push(`/commodity/allGoods?productId=${productId}`);
        }
      },
    },

    render() {
      return '';
    },
  };
</script>
