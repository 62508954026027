// 导出属商户店铺的路由规则
import Layout from '@/layout/default-layout.vue';
// 每个子模块 其实 都是外层是layout  组件位于layout的二级路由里面
export default {
  name: 'merchantstore',
  path: '/merchantstore',
  component: Layout,
  redirect: '/merchantstore/storemanagement',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-shop',
    title: '商户店铺管理',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'storemanagement',
      path: 'storemanagement',
      component: () => import('@/views/merchantStore/storeManagement'),
      meta: {
        icon: 'el-icon-sold-out',
        title: '店铺配置',
      },
    },
  ],
};
