// 导出属样品管理的路由规则
import Layout from '@/layout/default-layout.vue';
// 每个子模块 其实 都是外层是layout  组件位于layout的二级路由里面
export default {
    name: 'sample',
    path: '/sample',
    component: Layout,
    redirect: '/sample/samplelist',
    alwaysShow: true,
    meta: {
        icon: 'el-icon-picture',
        title: '供应链样品管理',
    },
    // 配置二级路的路由表
    children: [
        {
            name: 'samplelist',
            path: 'samplelist',
            component: () => import('@/views/SampleManagement/YpinList.vue'),
            meta: {
                icon: 'el-icon-document',
                title: '样品列表',
            },
        },
    ],
};
